.categories {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.categories .categories-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: 0.5;
  margin-bottom: 4em;
}

/* .categories-banner:before {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.5);
  pointer-events: none;
} */

.categories .collection {
  margin-bottom: 0.5em;
}

.categories .categories-banner img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.categories-banner .banner-text {
  position: absolute;
  /* width: 464px; */
  top: 202px;
  right: 50px;
  /* opacity: 0.8; */
  font-family: 'Lato-Bold', Helvetica;
  font-weight: 700;
  color: rgb(0, 144, 196);
  font-size: 48px;
  letter-spacing: 0.09em;
  line-height: normal;
  /* background-color: 'linear-gradient(263deg, #0090C4 27%, rgba(0, 145.76, 196, 0) 100%)'; */
}
.categories .products {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5em;
  margin: 0 auto;
  max-width: 1200px;
}

@media (min-width: 550px) {
  .categories .products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 8px;
    flex: 1;
  }

  .categories .category-item {
    width: 45%;
  }
}
@media (min-width: 850px) {
  .categories .products {
    justify-content: center;
  }

  .categories .category-item {
    width: 30%;
  }
}
