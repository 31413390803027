.banner-container {
  width: 100% !important;
  overflow-x: hidden;
}
.banner {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4em;
  position: relative;
  width: 100%;
  /* margin-bottom: 2em; */
}

.banner .banner-image {
  position: relative;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.banner .more-details {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px 18px;
  position: absolute;
  top: 346px;
  /* top: 100px; */
  /* left: 270px; */
  left: 119px;
  background-color: #0090c4;
  border-radius: 20px;
  transition: 500ms ease-in-out;
}

.banner .more-details:hover {
  background: var(--bg-box-shadow-2);
}

.banner .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Mukta", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: normal;
}

.banner .professionals {
  position: absolute;
  top: 189px;
  left: 113px;
  font-family: "Mukta", Helvetica;
  font-weight: 700;
  /* color: #9c353c; */
  color: #f6f7f8;
  text-transform: uppercase;
  text-shadow: -15px 5px 20px #000000;
  /* text-shadow: -15px 5px 20px #2596be; */
  font-size: 48px;
  letter-spacing: 0.96px;
}
.banner .professionals:hover,
.browse:hover {
  text-shadow: -16px 6px 15px #ced0d3;
}
.banner .browse {
  position: absolute;
  width: 336px;
  top: 374px;
  left: 119px;
  font-family: "Mukta", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: normal;
}

@media (max-width: 690px) {
  .banner .professionals {
    font-size: 30px;
  }
}
