/* ===== CATEGORY ITEM ==== */

.category-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5em;
  width: 356px;
  height: 378px;
  background: #eeeeee4b;
  border-radius: 10px;
  border: 1px solid #bab8b884;
  box-shadow: 0px 2px 4px 1px #a8a8a80b;
  padding: 0.8em;
}
.category-item .category-text {
  margin-bottom: 0.5em;
}
.category-item:hover {
  background-color: #077585;
}
.category-item img {
  width: 100%;
  height: 378px;
  object-fit: cover;
}

.category-item .name {
  font-weight: bold;
}
.category-item a {
  color: rgba(83, 175, 232, 0.702);
}
/* ===== CATEGORY ITEM ==== */

/* ===== PRODUCT ITEM ==== */

.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 400px;
  padding: 0.8em;
  box-shadow: 2px 4px 9px 2px #dde4e5;
  border-radius: 4px;
}

.product-item:hover {
  border: solid #077585;
}

.product-item .product-name {
  font-weight: 600;
}

.product-item .product-img {
  width: 100%;
  height: 70%;
  background-color: #dadada;
  background: transparent;
}
.product-img img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}
.product-item .button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  background-color: #077585;
  padding: 0.8em;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
}

.product-item .button:hover {
  opacity: 80%;
}
/* ===== PRODUCT ITEM ==== */
