.mobile-menu {
  /* border-right: 5px solid #fff; */
  animation: open-mobileMenu 2.2s ease;
}
.mobile-menu .submenu {
  display: flex;
  margin: 0;
  height: 90vh;
  overflow-y: auto;
  animation: open-mobileMenu 2.2s ease;
  margin-top: 2em;
}

.mobile-menu .mobile-wrap {
  margin-top: 2em;
}
.mobile-menu .submenu h5 {
  font-size: medium;
}
.mobile-menu .dropDown-header {
  width: 100%;
  background-color: #026e89a5;
  margin-top: 0.6em;
  border-radius: 5px;
  padding: 0.5em 0.4em;
  font-weight: 700;
}
.mobile-menu .submenu h5 {
  font-size: 18px;
}

.mobile-menu .nav {
}
.mobile-menu .nav ul {
  margin: 0;
  padding: 0;
}
.mobile-menu .nav li {
  margin: 0;
  padding: 0;
}
.mobile-menu .nav a {
  margin: 0;
  padding: 0;
}
.mobile-menu .list-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mobile-menu .list-group ul {
  padding-left: 0.5em;
}

.mobile-menu .nav-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile-menu .nav-link {
  font-size: 20px;
  font-weight: 900;
}

.mobile-menu-open {
  animation: open-mobileMenu 1.2s ease;
}
.mobile-menu-close {
  animation: close-mobileMenu 1s ease;
}

/* .mobile-menu .nav-items :nth-child(0) {
  animation: open-mobileMenu 2s ease;
}
.mobile-menu .nav-items :nth-child(1) {
  animation: open-mobileMenu 2.2s ease;
}
.mobile-menu .nav-items :nth-child(2) {
  animation: open-mobileMenu 2.4s ease;
}
.mobile-menu .nav-items :nth-child(3) {
  animation: open-mobileMenu 2.6s ease;
}
.mobile-menu .nav-items :nth-child(4) {
  animation: open-mobileMenu 2.8s ease;
} */
.mobile-menu .submenu::-webkit-scrollbar {
  /* display: none; */
  /* display: flex;
  width: 8px;
  background: #fff; */
}

@keyframes mobile-menu-open {
  /* start */
  0% {
    width: 0;
  }
  /* end */
  100% {
    width: 100%;
  }
}
@keyframes mobile-menu-close {
  /* start */
  100% {
    width: 0;
  }
  /* end */
  0% {
    width: 100%;
  }
}

@keyframes open-mobileMenu {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes close-mobileMenu {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
