.services {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1px;
  max-width: 1200px;
}

.services .page-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 900;
  margin: 1em 0;
  width: 100%;
  height: 5em;
  background-color: #647d830b;
}
.services .services-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin: 0 2em;
}

.services-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.6em;
  border-radius: 0.2em;

  background-color: #dee3e578;
}
.services-image img {
  width: 100%;
  object-fit: cover;
}
.services .services-summary {
  margin-top: 0.8em;
}
.services .services-name {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 1em 0;
}

@media (min-width: 550px) {
  .services-image {
    width: 80%;
  }
  .services-image img {
    width: 80%;
  }
}
@media (min-width: 760px) {
  .services .services-item {
    display: flex;
    justify-content: space-between;
    gap: 1em;
  }
  .services-image,
  .services-summary {
    width: 45%;
  }
}
