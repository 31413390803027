.view-product {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1px;
}

.view-product .page-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 900;
  margin: 1em 0;
  width: 100%;
  height: 5em;
  background-color: #647d830b;
  /* height: 50em; */
}
.view-product .view-product-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin: 0 2em;
}

.view-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.6em;
  border-radius: 0.2em;

  background-color: #dee3e578;
}
.view-image img {
  width: 80%;
  object-fit: cover;
}

.view-product .product-name {
  font-size: 1.7rem;
  font-weight: 700;
  margin: 1em 0;
}

.view-product .inquire-btn {
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 1.8em;
  background: #49bad2;
  padding: 0.8em 1.5em;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.9rem;
}

.view-product .inquire-btn:hover,
.view-product .product-description:hover {
  opacity: 0.7;
}
.view-product .product-description {
  width: fit-content;
  padding: 0.8em 1.2em;
  background-color: #49bad2;
  margin-bottom: 0;
  border-radius: 0.5em 0.5em 0 0;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 600;
  transform: translateY(1.7em);
}

.view-product .full-description {
  border: 1px solid #dad3d3;
  border-radius: 0 0.5em 0.5em 0.5em;
  padding: 0.8em;
  margin-top: 0px;
  box-shadow: var(--bg-box-shadow-2);
}

@media (min-width: 760px) {
  .view-product .product-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1em;
  }
}
