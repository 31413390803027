.products {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.products-head {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-weight: 900;
  font-size: 34px;
  margin: 1em 0;
}

.products .products-wrap {
  margin: 6em 1em;
}
.products .product-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
}

.products-summary {
  border: 2.5px solid #f4f2f2;
  box-shadow: var(--box-shadow-3);
  border-radius: 6px;
  padding: 1.2em 0.8em;
  text-align: justify;
  width: 90%;
  margin: 1em auto;
}
.partners-logos {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  flex-wrap: wrap;
  margin: 0 auto;
}
.partners-logos .partner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  color: #000;
  text-align: start;

  font-size: 14px;
}
@media (min-width: 1100px) {
  .partners-logos .partner {
    margin: 0 20px;
  }
}
@media (min-width: 870px) {
  .products .product-items {
    flex-direction: row;
  }
  .product-items .product-item {
    width: 30%;
  }

  .partners-logos .partner {
    margin: 0 5px;
  }
}

@media (min-width: 700px) {
  .products .product-items {
    flex-direction: row;
    justify-content: center;
    gap: 1em;
    flex-wrap: wrap;
  }
  .product-items .product-item {
    width: 45%;
  }
}

@media (max-width: 550px) {
  .text {
    font-size: 36px;
    letter-spacing: -18px !important;
  }
}
