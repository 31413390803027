@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;900&family=Mukta:wght@200;300;400;700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Mukta", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
main {
  margin-top: 6em;
}
html {
  scroll-behavior: smooth;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1800px;
  gap: 10px;
  margin: 2em 0.5em;
}
:root {
  --bg-box-shadow-1: linear-gradient(
    180deg,
    rgb(12.77, 190.73, 255) 0%,
    rgba(44, 90, 106, 0.759) 100%,
    rgba(19, 157, 207, 0.837) 100%
  );
  --bg-box-shadow-2: linear-gradient(#0090c4, #0092c400);

  --transition-1: 500s ease-in-out;
  --opacity50: 50%;
}

.hover-opacity:hover {
  opacity: var(--opacity50);
}
.nav .nav-link {
  list-style-type: none;
  color: #000 !important;
}

.testimonial-video {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.testimonial-video iframe {
  width: 100%;
}

.testimonial-video .heading {
  margin: 1em 0;
}

.testimonial-video .testimonials-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
  background: #000;
  color: #fff;
  padding: 0.4em 1em;
  border-radius: 0.2em;
  align-self: flex-end;
  letter-spacing: 0.05em;
  margin-top: 0.4em;
  transition: opacity 500ms ease-in;
}
.testimonials-btn:hover {
  opacity: 50%;
}
/* BLUR  BG */
.blur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 99;
}

/* BLUR  BG */

/*================ BANNER============*/

/*================ BANNER============*/
.scrollbar2::-webkit-scrollbar-thumb {
  position: absolute;
  right: 0;
  width: 1.4em;
  background: rgb(42, 42, 42);
  border-radius: 5px;
}

.page-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-size: 24px; */
  /* font-weight: 900; */

  margin: 1em 0;
  width: 100%;
  height: 5em;
  background-color: #647d830b;
  border-radius: 5px;

  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 47px;
  letter-spacing: 0.15rem;
}

.divider2 {
  width: 90%;
  height: 2px;
  background: #231f1f;
  margin: 1.2em auto;
  box-shadow: var(--bg-box-shadow-2);
}
.divider3 {
  width: 90%;
  height: 2px;
  background: #f3f2f2;
  box-shadow: var(--bg-box-shadow-3);
  margin: 1.2em auto;
}

.overlay {
  /* border-image-source: linear-gradient(red, blue);
  border-width: 10px;
  border-style: solid;
  border-image-slice: fill; */
  border-image: linear-gradient(hsl(240 100% 50% / 0.3), hsl(0 100% 50% / 0.3))
    fill 1;
}
@media (max-width: 550px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 750px) {
  .testimonial-video iframe {
    height: 400px;
  }
}
@media (max-width: 450px) {
  .testimonial-video iframe {
    height: 200px;
  }
}
