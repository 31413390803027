.input-wrap {
  /* width: 320px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-wrap input {
  outline: none;
  border: 0.5px solid #6c6c6c80;
  width: 100%;
  height: 50px;
  font-size: 16px;
  border-radius: 25px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px 1px #0000000d;
  color: #d6d6d6;
  padding: 0 0.8em;
}

.input-wrap ::placeholder {
  padding: 12px;
  font-size: 14px;
  color: #6d6d6d;
}
.input-wrap input:focus {
  border-color: #003242;
}

.input-error-border {
  border-color: #b94a48 !important;
}

.input-error {
  position: relative;
  padding: 10px 10px;
  background: #b94a48;
  width: 100%;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.input-success {
  position: relative;
  padding: 10px 10px;
  background: #0392c2;
  width: 100%;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.input-wrap textarea {
  color: #000;
  outline: none;
  border: 0.5px solid #6c6c6c80;
  width: 100%;
  height: 150px;
  font-size: 14px;
  border-radius: 25px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px 1px #0000000d;
  color: #d6d6d6;
  padding: 1em 0.5em;
}
.input-wrap textarea::-webkit-scrollbar {
  width: 0.9em;
}
.input-wrap textarea::-webkit-scrollbar-thumb {
  background-color: #7272729a;
  border-radius: 20px;
}
