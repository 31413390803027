.card {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 10px;
}

.card:hover {
  opacity: 50%;
  scale: 1.02;
  transition: 0.5s;
}
.card-wrap {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.card-header {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
}
.card-title {
  font-size: 32px;
  font-weight: 500;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
}

.description {
  font-family: Mukta;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  /* text-align: center; */
}
.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

.card a {
  color: #ffffff;
}
.card a:hover {
  color: #4279e7;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.5s;
}
/* ===== SERVICE CARD ====== */
.service-card {
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
}
.service-card .service-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1e1e1e;
  width: 47px;
  height: 51px;
  border-radius: 7.29px;
  padding: 0.5em 0.8em;
}

.service-icon svg {
  width: 47px;
  height: 51px;
}
.service-card .contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.service-card .service-title {
  font-size: 16px;
  font-weight: 700;
  color: #5670a2;
}
.service-card .service-text {
  font-weight: 400;
}
/* ===== SERVICE CARD ====== */
/* ===== OUR TEAM CARD ====== */
.team-item {
  display: flex;
  flex-direction: column;
  gap: 0.6em;
}
.team-item .member-name,
.team-item .position {
  display: flex;
  justify-content: center;
}
.team-item .member-name {
  font-weight: 600;
  font-size: 16px;
}
/* ===== OUR TEAM CARD ====== */

/* =====MEDIA QUERIES ====== */
@media (min-width: 550px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 750px) {
  .card {
    height: 250px;
  }
  .container {
    width: 80%;
    margin: 2.5em auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

/* =====MEDIA QUERIES ====== */
