.submenu {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.submenu .list-group {
  width: 25%;
}
.submenu ul,
.submenu li {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #000;
}

.submenu li a:hover {
  transition: color 500ms ease-in;
  color: #000000;
}

.submenu a {
  text-decoration: none;
  color: #ffffff;
}
.submenu h5 {
  font-size: 18px;
  font-weight: 700;
}
.nav-link-header {
  border-bottom: 0.08em solid #ffffff;
}

.list-group ul li {
  font-weight: bold;
  font-size: 14px;
}
