.main-footer {
  color: #fff;
  background-color: #363636;
  max-height: fit-content;
  padding-bottom: 1.9em;
  margin-top: 1em;
}
.top-footer {
  /* display: grid; */
  /* grid-template-columns: 1.4fr 1fr; */
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  background-color: #e7e6e6;
  padding: 2.9em 0.5em;
  margin: 0 auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.the-healix {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 20px;
  color: #000;
}
.thehealix-header {
  font-size: 24px;
  font-weight: 900;
  padding: 0;
  margin-bottom: -8px;
}
.the-healix span {
  font-size: 14px;
  font-style: italic;
}
.thehealix-content {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
/* .partners {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
} */
.partner img {
  width: 100px;
}
#radiometer img,
#elga img {
  /* width: 130px; */
}

.middle-footer {
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  width: 100%;
  background-color: #363636;
}
.left-middle {
  display: flex;
  justify-content: space-evenly;
}
.information a {
  text-decoration: none;
  color: #fff;
}
.information a:hover {
  cursor: pointer;
  color: #12aee2;
}
.information ul {
  list-style: none;
  padding-left: 0;
}
.contact-support {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 4px;
}
.contact-content {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 5px;
}
.contact-content svg {
  width: 18px;
  /* fill: #fff; */
}
.contact-content :nth-child(1) {
  width: 28px;
}
.social-media {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.socials {
  display: flex;
  align-items: center;
  padding-top: 6px;
  gap: 6px;
}

.socials svg {
  fill: #fff;
  width: 25px;
  height: 25px;
  transition: scale 500ms;
}

.socials svg:hover {
  scale: 1.5;
  z-index: 2;
}

.divider {
  width: 66%;
  height: 2px;
  background: #d6d4d4;
  margin: 0.2em;
}

.signup-newsl input {
  border: none;
  background-color: #fff;
  outline: none;
  border-radius: 30px;
  padding: 12.5px 30px;
  width: 60%;
  font-weight: 400;
  font-size: 16px;
  color: #000;
}
.signup-newsl input::placeholder {
  color: #000;
}
.subscribe {
  display: flex;
}

.subscribe button {
  color: #fff;
  font-weight: 600;
  background-color: #12aee2;
  border-radius: 30px;
  padding: 0.8em 1.6em;
  border: none;
  transform: translateX(-30px);
  transition: var(--transition-1);
}

.subscribe button:hover {
  background: var(--bg-box-shadow-1);
}

.bottom-footer {
  margin-top: 1em;
  text-align: center;
}

@media (max-width: 1400px) {
  .partner img {
    width: 100px;
  }
}
@media (max-width: 1200px) {
  .divider {
    width: 78%;
  }
  .partner img {
    width: 90px;
  }
  .left-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1.6em;
  }
}

@media (max-width: 800px) {
  .middle-footer {
    padding: 0 2em;
  }
  .left-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .divider {
    width: 100%;
  }
  .signup-newsl input {
    width: 100%;
  }
}

@media (max-width: 606px) {
  .middle-footer {
    display: flex;
    flex-direction: column;
  }
  .left-middle {
    padding-left: 0;
  }
  .partner {
    flex: 25%;
  }
}

@media (max-width: 500px) {
  /* .the-healix {
    display: flex;
    flex-direction: column;
    gap: 5px;
  } */
  .thehealix-header {
    display: none;
  }
  .thehealix-content span {
    text-align: center;
    font-size: 12px;
  }
}

@media (max-width: 395px) {
  .cont-header {
    display: none;
    gap: 0;
  }
  .espan {
    padding-left: 2em;
  }
}
