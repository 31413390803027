.testimonials {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonials-wrap {
  padding: 0.5em 1em;
}
.testimonials-content {
  display: flex;
  justify-content: center;
}
.testimonials .testimial-header {
  font-size: 32px;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 0.15rem;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.testimonials .testimonial-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 1em;
}
.testimonials .description {
  font-family: Mukta;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

/* ====TESTIMONIAL ITEM/CARD ==== */

.testimonial-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
  padding: 2em;
  background: #e0dcdc;
  border-radius: 5px;
  width: 90%;
  height: fit-content;
  line-height: normal;
  margin-top: 1em;
  margin-bottom: 1em;
  transition: opacity 500ms ease-in;
}
.testimonial-item:hover {
  opacity: 50%;
}
.testimonial-item .profile-picture {
  border: 2px solid white;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial-item .text-wrap {
  font-family: "Mukta-Light", Helvetica;
  font-weight: lighter;
  text-align: left;
}
.testimonial-item .name {
  width: fit-content;
  font-family: "Mukta-Bold", Helvetica;
  font-weight: 700;
  font-size: 20px;
}
.testimonial-item .position-wrap {
  width: fit-content;
  font-family: "Mukta-Regular", Helvetica;
  font-weight: 400;
  font-size: 16px;
  /* padding-bottom: 0.5em !important; */
}

.testimonials-content {
  margin-top: 1em auto;
}
/* ====TESTIMONIAL ITEM/CARD ==== */
@media (max-width: 700px) {
  .testimonial-item {
    width: 100%;
  }
  .testimonials-content {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 700px) {
  .testimonial-item {
    width: 40%;
  }
  .testimonials-content {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5em;
  }
}

@media (min-width: 950px) {
  .testimonial-item {
    width: 30%;
  }
  .testimonials-content {
    display: flex;
    flex-wrap: wrap;
  }
}
