/*========== PRIMARY HEADER ==================== */

.main-header {
  position: fixed;
  top: 0;
  height: 90px;
  z-index: 99;
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.main-header .nav {
  display: flex;
  gap: 1em;
  align-items: center;
  margin: 1em 2em;
  justify-content: space-between;
}

.main-header .nav-list {
  list-style: none;
  font-size: 0.9rem;
  margin-left: 2em;
}
.nav-list .nav-link {
  width: 251px;
  height: 150px;
  color: #000;
  padding: 2px 0.5em;
  text-transform: uppercase;
}
.products-dropdown svg {
  transform: translateY(0.35em);
}
.main-header .nav-list .nav-link:hover {
  color: white;
  background-color: #f58484;
  border-radius: 10px;
}
.header-logo svg {
  width: 140px;
}

/*========== PRIMARY HEADER ==================== */
/*========== DROP MENU HEADER ==================== */
.drop-menu {
  position: absolute;
  width: 90%;
  height: fit-content;
  top: 4.56em;
  background-color: #138891b7;
  z-index: 999;
  margin: 0 auto;
  border-radius: 0 0 20px 20px;
  box-shadow: var(--bg-box-shadow-2);
  animation: dropdown-menu-open00 1s ease;
  /* transition: opacity 1s ease, transform 1s ease; */
}

@keyframes dropdown-menu-open {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(6px);
  }
}
@keyframes dropdown-menu-open00 {
  0% {
    /* height: 0; */
    opacity: 0;
  }

  100% {
    /* height: 100%; */
    opacity: 1;
  }
}

/*========== DROP MENU HEADER ==================== */

/*========== MOBILE MENU  ==================== */
.ham-menu {
  display: flex;
  gap: 4px;
  align-items: center;
}

.ham-menu,
.mobile-menu {
  display: none;
}
.mobile-menu .nav-items {
  /* margin-left: 0 auto; */
}
.mobile-menu .nav-link {
  width: 201px;
  height: 40px;
  color: #000;
  padding: 2px 0.5em;
}

.mobile-menu {
  position: fixed;
  right: -9rem;
  top: 0em;
  background: #3487b4df;
  border-radius: 20px;
  height: 100vh;
  width: 475px;
  padding: 10px 1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  user-select: none;
  border: 6px solid #ffffff;
  z-index: 999;
}
.mobile-menu .mobile-nav {
  display: flex;
  flex-direction: column;
}
.products-dropdown {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}
.menu-title {
  font-weight: 700;
  font-size: large;
  /* transform: translateY(-3px); */
}
.menu-icon {
  border-radius: 20%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0 4px;
  padding-top: 4px !important;
  transition: all 500ms ease-in;
}
.mobile-menu-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 1.8em;
}
.close-menu {
  position: fixed;
  font-weight: 700;
  right: 0;
  margin-right: 4px;
}
.back-to-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.3em;
  fill: #fff;
  position: absolute;
  background-color: #0000007a;
}
.menu-icon:hover {
  border-radius: 50%;
}

/*========== MOBILE MENU  ==================== */

/*========== MEDIA QUERIES ==================== */

@media (max-width: 900px) {
  .nav {
    /* justify-content: space-between;
    margin: 0 6px; */
  }
  .nav-list,
  .drop-menu {
    display: none;
  }

  .ham-menu,
  .mobile-menu {
    display: flex;
  }
}
/*========== MEDIA QUERIES ==================== */
