/* ====ABOUT  ==== */
.about {
  /* text-align: left; */
  max-width: 1200px;
  margin: 0 auto;
}

.about .about-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8em !important;
}

.about .heading {
  font-size: 24px;
  margin-bottom: 1em;
}
.about1 .heading {
  font-size: 18px;
  margin-bottom: 1em;
  letter-spacing: normal !important;
}

.about .about-wrapper {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.about .about-header,
.heading {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0.15rem;
  margin-bottom: 0.5em;
}
.about .about1,
.about2 {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em 1em;
}
.about .about2 {
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: #b398981a;
  border-radius: 5px;
  padding: 2em 1em;
}

.about2 .left-section {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}
.about2 .service-icon svg {
  width: 100% !important;
  height: 100% !important;
}
.about img {
  width: 100%;
  object-fit: cover;
  border-radius: 0.8em;
}
.about3 .team-item {
  margin-bottom: 2em;
  /* border-bottom: 2px solid rgb(230, 229, 229); */
  box-shadow: 0px 4px 4px 0px rgba(228, 223, 223, 0.25);
}

/* ====ABOUT  ==== */

/* ====MEDIA QUERY  ==== */

@media (min-width: 450px) {
  .about3 .contents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .about3 .team-members {
    width: 45%;
    margin-bottom: 2em;
  }
  .about img {
  }
}

@media (min-width: 750px) {
  .about .about2 {
    display: flex;
    flex-direction: row;
    gap: 0.8em;
  }

  .about2 .left-section,
  .about2 .right-section {
    width: 90%;
  }
  .right-section {
    display: flex;
    align-self: center;
    justify-self: flex-end;
    /* margin-top: 1em; */
  }
  .right-section img {
    /* align-self: center; */
    object-fit: contain !important;
    height: fit-content;
  }

  .about .about1 {
    flex-direction: row;
    justify-content: space-between;
  }

  .about .about-img,
  .about .about1-text {
    width: 45%;
  }
}

/* ====MEDIA QUERY  ==== */
