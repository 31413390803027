.contact {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
}

.contact .contact-wrapper {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.contact .contact-header {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0.15rem;
  margin-bottom: 0.5em;
}

/* ====CONTACT ITEM/CARD ==== */

.contact-item {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 8px;
  border: 1px solid #6c6c6c52;
  box-shadow: 0px 2px 4px 1px #0000000d;
  border-radius: 5px;
  padding: 2em;
  margin-bottom: 2em;
}

.contact-item:hover {
  opacity: var(--opacity50);
}
.contact-item .contact-header {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.contact-item .contact-wrap {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}
.contact-item .contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  background: #28687d;
  border-radius: 5px;
  padding: 4px;
}
.contact-item .label {
  font-weight: 600;
}

.contact-item a {
  color: #464646f0;
}

/* ====CONTACT ITEM/CARD ==== */

/* ====CONTACT FORM==== */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.contact-form .form-header,
.faq-header {
  font-family: "Oswald-Regular", Helvetica;
  font-weight: 400;
  color: #1e1e1e;
  font-size: 1.2rem;
  margin-bottom: 1.2em;
  margin-top: 1.2em;
}

.form-inputs {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1.5em;
}

.form-inputs .submit-btn {
  display: flex;
  width: 178px;
  height: 32px;
  align-items: center;
  justify-content: space-around;
  padding: 4px 8px;
  border-radius: 25px;
  border: none;
  background-color: #0090c4;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-family: "Mukta";
  transition: all 500ms ease-in;
}
.submit-btn:hover {
  background: var(--bg-box-shadow-1);
}
.form-inputs textarea {
  color: #000;
  outline: none;
  border: 0.5px solid #6c6c6c80;
  width: 100%;
  height: 150px;
  font-size: 14px;
  border-radius: 25px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px 1px #0000000d;
  color: #d6d6d6;
  padding: 1.5em 1em !important;
}

.form-inputs textarea::-webkit-scrollbar {
  width: 0.9em;
}
.form-inputs textarea::-webkit-scrollbar-thumb {
  background-color: #7272729a;
  border-radius: 20px;
}

/* ====CONTACT FORM==== */

/* ====FAQs ACCORDION==== */

.szh-accordion__item .szh-accordion__item-heading {
  text-transform: uppercase;
  padding: 17px 22px;
  background: #fff;
  box-shadow: 4px 4px 27px #8c949c3b;
}
.szh-accordion__item {
  color: #484747;
}
.szh-accordion__item:hover {
  cursor: pointer;
  scale: 0.95;
  transition: 1.5s;
}

.szh-accordion__item-content {
  line-height: 2;
  border-bottom: 1.5px solid #f1f0f0;
}
.szh-accordion__item-panel {
  padding: 0.5em 1em;
}

.szh-accordion__item .szh-accordion__item-btn {
  color: #000 !important;
  font-weight: 600;
  font-size: 14px;
  background: #fff !important;
  border: none !important;
  letter-spacing: 1.6px;
  line-height: 25.1px;
  text-transform: uppercase;

  /* border: 2px solid #000; */
}
/* ====FAQs ACCORDION==== */

@media (min-width: 550px) {
  .contact-item {
    width: 40%;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5em;
  }
}

@media (min-width: 900px) {
  .contact-item {
    width: 30%;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
  }
  .form-faq {
    display: flex;
    gap: 1.8em;
    justify-content: space-between;
  }
  .contact-form,
  .faq {
    width: 45%;
  }
}
